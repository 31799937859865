.text {
  white-space: normal;
}

.withOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.subText {
  text-transform: initial;
  font: var(--themeTextSmall);
  color: var(--themeTextColorMuted);
  font-weight: normal;
}
