.content {
  position: relative;
}

.arrowEdge::before,
.arrowEdge::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  margin: auto;
  transition: var(--themeTransition);
  border-right: 0 solid var(--themeCard);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.arrowEdge::after {
  border-right: 0 solid var(--themeCardLight);
}

.arrowEdge.withoutSubstrate::before {
  border-right: none;
}

.arrowEdge.darkArrow::after {
  border-right: 0 solid var(--themeDivider);
}

.active .arrowEdge::before,
.active .arrowEdge::after {
  border-right-width: 10px;
}

.tab {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.active {
  cursor: default;
}

.fixedHeightTab {
  height: 40px;
}
