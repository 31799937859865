.package {
  display: flex;
  align-items: center;
  padding: 5px 0 5px 10px;
  min-height: 50px;
}

.package.active {
  background-color: var(--skinHeader);
}

.icon {
  width: 40px;
  height: 40px;
}

.title {
  text-transform: uppercase;
}
