.price {
  width: 80px;
}

.header {
  min-height: 50px;
  display: flex;
  align-items: center;
}

.info {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
}
