.icons {
  position: relative;
  display: flex;
}

.iconSmall {
  position: absolute;
  right: -20px;
  bottom: -10px;
  z-index: 1;
}
