.wrap {
  display: inline-block;
  height: 100%;
  width: 100%;
  text-align: center;
}

.horizontalWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verticalWrap {
  composes: horizontalWrap;
  flex-direction: column;
  overflow: hidden;
}

.wrap,
.verticalWrap {
  opacity: 0.5;
  filter: grayscale(100%);
  transition: var(--themeTransition);
}

.text {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  font: var(--themeH4);
  color: var(--themeTextColor);
}

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.icon,
.text {
  display: inline-block;
  vertical-align: middle;
}

.wrap .icon + .text {
  margin-left: 10px;
}

.verticalWrap .icon + .text {
  margin-top: 4px;
}

.logo {
  composes: icon;
  width: 80px;
  height: 20px;
  max-width: 100%;
}

/* States */
.wrap:hover,
.verticalWrap:hover,
.active {
  opacity: 1;
  filter: none;
}

.visa-mc,
.midas,
.card {
  composes: logo;
  background-image: url(@core/payment/widgets/tabs/images/card.svg);
}

.inverse.visa-mc,
.inverse.midas,
.inverse.card {
  background-image: url(@core/payment/widgets/tabs/images/cardInverse.svg);
}

.bccard {
  composes: logo;
  width: 85px;
  background-image: url(@core/payment/widgets/tabs/images/bccard.svg);
}

.inverse.bccard {
  background-image: url(@core/payment/widgets/tabs/images/bccardInverse.svg);
}

.bancontact {
  composes: logo;
  width: 50px;
  height: 35px;
  background-image: url(@core/payment/widgets/tabs/images/bancontact.svg);
}

.inverse.bancontact {
  background-image: url(@core/payment/widgets/tabs/images/bancontactInverse.svg);
}

.cartebancaireInverse,
.cartebancaire {
  composes: logo;
  width: 32px;
  height: 22px;
  background-image: url(@core/payment/widgets/tabs/images/carteBancaire.svg);
}

.paypal {
  composes: logo;
  width: 60px;
  background-image: url(@core/payment/widgets/tabs/images/paypal.svg);
}

.inverse.paypal {
  background-image: url(@core/payment/widgets/tabs/images/paypalInverse.svg);
}

.paygarden {
  composes: logo;
  width: 35px;
  height: 35px;
  background-image: url(@core/payment/widgets/tabs/images/paygarden.svg);
}

.inverse.paygarden {
  background-image: url(@core/payment/widgets/tabs/images/paygardenInverse.svg);
}

.sepa,
.micropaymentsepa,
.novalnetsepa,
.commdoosepa,
.trustpaysepa,
.saltedgeais {
  composes: logo;
  width: 63px;
  height: 18px;
  background-image: url(@core/payment/widgets/tabs/images/sepa.svg);
}

.inverse.sepa,
.inverse.micropaymentsepa,
.inverse.novalnetsepa,
.inverse.commdoosepa,
.inverse.trustpaysepa,
.inverse.saltedgeais {
  background-image: url(@core/payment/widgets/tabs/images/sepaInverse.svg);
}

.konbini {
  composes: logo;
  width: 32px;
  height: 32px;
  background-image: url(@core/payment/widgets/tabs/images/konbini.svg);
}

.inverse.konbini {
  background-image: url(@core/payment/widgets/tabs/images/konbiniInverse.svg);
}

.klarna {
  composes: logo;
  width: 60px;
  height: 30px;
  background-image: url(@core/payment/widgets/tabs/images/klarna.svg);
}

.sofort {
  composes: logo;
  width: 40px;
  height: 24px;
  background-image: url(@core/payment/widgets/tabs/images/sofort.svg);
}

.sepatransfer {
  composes: logo;
  width: 52px;
  height: 28px;
  background-image: url(@core/payment/widgets/tabs/images/sepaTransfer.svg);
}

.sofortuberveisung {
  composes: logo;
  width: 90px;
  height: 28px;
  background-image: url(@core/payment/widgets/tabs/images/sofortUberweisung.svg);
}

.sofortuberveisung.inverse {
  background-image: url(@core/payment/widgets/tabs/images/sofortUberweisungInverse.svg);
}

.nuveimybank {
  composes: icon;
  width: 50px;
  height: 28px;
  background-image: url(@core/payment/widgets/tabs/images/mybank.svg);
}

.inverse.nuveimybank {
  background-image: url(@core/payment/widgets/tabs/images/mybankInverse.svg);
}

.paybybank {
  composes: icon;
  height: 20px;
  min-width: 20px;
  background-image: url(@core/payment/widgets/tabs/images/paybybank.svg);
}

.inverse.paybybank {
  background-image: url(@core/payment/widgets/tabs/images/paybybankInverse.svg);
}

.ideal,
.nuveiideal,
.trustpayideal {
  composes: icon;
  width: 35px;
  height: 30px;
  background-image: url(@core/payment/widgets/tabs/images/iDeal.svg);
}

.inverse.ideal,
.inverse.nuveiideal,
.inverse.trustpayideal {
  background-image: url(@core/payment/widgets/tabs/images/iDealInverse.svg);
}

.eps {
  composes: logo;
  width: 32px;
  height: 20px;
  background-image: url(@core/payment/widgets/tabs/images/eps.svg);
}

.inverse.eps {
  background-image: url(@core/payment/widgets/tabs/images/epsInverse.svg);
}

@media (min-width: 768px) and (max-width: 1023px) {
  .logo {
    width: 60px;
  }
}
