.image {
  height: auto;
}

.fluid {
  width: 100%;
}

.fixed200 {
  width: 200px;
}

.fixed120 {
  width: 120px;
}

.fixed40 {
  width: 40px;
}
