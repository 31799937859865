.wrap {
  text-align: center;
}

.title {
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  100% {
    transform: scale(0.75);
  }
  30%,
  70% {
    transform: scale(1);
  }
}
