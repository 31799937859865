.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.round {
  border-radius: var(--themeRadiusLarge);
}

.clickable {
  cursor: pointer;
}

.preview {
  position: relative;

  /* Add  flex for correct view in Ios 10 */
  display: flex;
  align-items: center;
  width: inherit;
  border-radius: inherit;
}

.shadow {
  box-shadow: var(--themeShadow1);
}

/* styles for sizes */
.full {
  height: inherit;
}

.middle {
  width: 50%;
}

/* Need for other browsers */
@supports (position:-webkit-sticky) or (position:sticky) {
  .middle {
    height: auto;
  }
}

@media (max-width: 736px) {
  .middle {
    width: 100%;
  }
}
