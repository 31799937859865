.largeText {
  font: var(--themeH3);
}

.primary {
  background: var(--skinPrimaryLite);
}

.success {
  background: var(--skinSuccessLite);
}

.light {
  background: var(--themeCardLight);
}

.list {
  border-radius: inherit;
}
