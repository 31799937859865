.wrap {
  position: relative;
  border-radius: inherit;
}

.icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
}

.squareIcon {
  composes: icon;
  border-radius: var(--themeRadiusSmall);
}

.list {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
