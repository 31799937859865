.wrap {
  display: flex;
  flex-wrap: wrap;
}

.tab {
  flex: 1 0 33%;
}

/* stylelint-disable selector-max-specificity */
.tab:first-child:nth-last-child(4),
.tab:first-child:nth-last-child(4) ~ .tab {
  flex: 1 0 50%;
}

.disabled {
  filter: grayscale(100%);
  pointer-events: none;
}
